import { useNavigate } from 'react-router-dom';
import Alert from '../../assets/Alert';
import AlertSection from '../../components/Common/AlertSection/AlertSection';
import PlayerHeader from '../../components/Common/PlayerHeader';
import Layout from '../../components/Common/Layout/Layout';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import HowPlayVideo from '../../components/HowPlayVideo/HowPlayVideo';
import { useGetAllBattlesTypes } from '../../apis/hooks/useBattlesType';
import Loader from '../../components/Common/Loader/Loader';
import { io } from 'socket.io-client';
import { useUserData } from '../../Context/UserContext';
import { MdInfo } from 'react-icons/md';

const LandingPage = () => {
    const navigate = useNavigate();
    const { user } = useUserData();

    const [isOpenGuide, setIsOpenGuide] = useState<boolean>(false);
    const { data: battlesType, isLoading } = useGetAllBattlesTypes();

    const handleGameClick = (game: any) => {
        if (game?.battlesType === 'Classic') {
            navigate(`/battle/${game?.battlesType}/${game?.id}`);
        }
    };

    const renderGameCards = () => {
        if (battlesType?.length === 0) {
            return <p>No games available at the moment.</p>;
        }
        return battlesType?.map((type: any, index: number) => (
            <div
                key={index}
                onClick={() => handleGameClick(type)}
                className="relative cursor-pointer"
            >
                {type?.battlesType !== 'Classic' && (
                    <div className="ribbon ribbon-top-right">
                        <span>Coming Soon</span>
                    </div>
                )}
                <img
                    loading="lazy"
                    src={type?.files?.fullUrl}
                    className={`rounded-lg ${type?.battlesType !== 'Classic' ? '' : ''}`}
                    alt={`${type?.battlesType} game`}
                />
            </div>
        ));
    };

    return (
        <Layout>
            <section className="w-[90%] mx-auto">
                <AlertSection
                    className="p-2 bg-yellow-100 text-sm"
                    alertType="WARNING"
                    message="NOTICE: सभी यूजर ध्यान दें WIN होने के बाद रूम कोड वाला स्क्रीनशॉट ही डालें अन्यथा आपकी टेबल CANCEL कर दी जाएगी. LOSS होने पर LOSS नहीं डाला या ग़लत अपडेट किया तो 50 Rs कि पेनल्टी लगा दी जायगी."
                />
                <br />
                {!user?.userdetails?.kyc ? (
                    <div className="flex justify-between p-2 border border-[#212529] rounded-md">
                        <div className="flex flex-col">
                            <p className="text-xs">KYC Status</p>
                            <p className="flex gap-1 font-semibold">
                                <span className="font-semibold">Pending</span>
                                <MdInfo color="blue" size={24} />
                            </p>
                        </div>
                        <div className="font-medium">
                            <button
                                className="p-2 text-[#198754] border border-[#198754] hover:bg-[#198754] hover:text-white rounded-md"
                                onClick={() => navigate('/kyc')}
                            >
                                Complete KYC
                            </button>
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                <div className="flex items-center justify-between gap-1 mt-2 mb-6">
                    <span className="text-[28px] font-normal">Games</span>
                    <button
                        className="cursor-pointer flex gap-1 text-[#0d6efd] hover:text-white hover:bg-[#0d6efd] w-[93.37] h-[38px] border border-[#0d6efd] items-center px-2 rounded-md leading-loose"
                        onClick={() => setIsOpenGuide(true)}
                    >
                        <Alert className="w-[16px]" />
                        Guide
                    </button>
                </div>
                {isLoading ? (
                    <div className="flex flex-col items-center justify-center gap-8 h-[300px]">
                        <Loader scale={4} />
                        <p>Please wait. Loading...</p>
                    </div>
                ) : (
                    <div className="grid grid-cols-2 gap-2">
                        {renderGameCards()}
                    </div>
                )}
            </section>
            {isOpenGuide && (
                <HowPlayVideo
                    isOpen={isOpenGuide}
                    onClose={() => setIsOpenGuide(false)}
                />
            )}
        </Layout>
    );
};

export default LandingPage;
