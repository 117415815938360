import { useState } from 'react';
import Layout from '../Common/Layout/Layout';
import useWithdrawalPostDetails from '../../apis/hooks/useWithdrawalPostDetails';
import { toast } from 'react-toastify';
import useWithdrawalDetailsData from '../../apis/hooks/useWithdrawalDetailsData';
import bank from '../../assets/Blank.png';
import { useForm } from 'react-hook-form';
import { useUserData } from '../../Context/UserContext';
import Loader from '../Common/Loader/Loader';
import useCreateAccountDetails from '../../apis/hooks/useWithdrawalPostDetails';
import { useNavigate } from 'react-router-dom';

interface WithdrawalBankForm {
    accountName: string;
    accountNumber: string;
    bank: string;
    IFSCCode: string;
    amount: string;
}

const WithdrawalBank = ({ data }: { data?: any }) => {
    const { user } = useUserData();
    const navigate = useNavigate();

    // React Hook Form for form state management
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<WithdrawalBankForm>({
        defaultValues: {
            accountName: data?.accountName ?? '',
            accountNumber: data?.accountNumber ?? '',
            bank: data?.bank ?? '',
            IFSCCode: data?.IFSCCode ?? '',
            amount: '',
        },
    });

    const { mutate: submitBank, isLoading } = useCreateAccountDetails();

    const onSubmit = (formData: WithdrawalBankForm) => {
        const submitData = {
            primaryChoice: 'BANK',
            userId: user?.userdetails?.id,
            accountName: formData.accountName ?? '',
            accountNumber: +formData.accountNumber,
            bank: formData.bank ?? '',
            IFSCCode: formData.IFSCCode ?? '',
            amount: +formData.amount,
        };

        submitBank(submitData, {
            onSuccess: (res: any) => {
                if (res?.error) {
                    toast(
                        Array.isArray(res?.message)
                            ? res?.message[0]
                            : 'Details not Submitted',
                        { type: 'error' }
                    );
                } else if (res?.data?.message) {
                    navigate('/battle/Classic/bxq03ero6mdmemfrjw82t6yg0');
                    toast(res?.data?.message ?? 'Details Submitted!', {
                        type: 'success',
                    });
                }
            },
        });
    };

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-4"
            >
                {/* Account Holder Name Input */}
                <div className="flex flex-col gap-1">
                    <label>Account Holder Name</label>
                    <input
                        type="text"
                        placeholder="Account Name"
                        className="w-full border rounded h-[38px] focus:outline-none px-2"
                        {...register('accountName', {
                            required: 'Account name is required',
                        })}
                    />
                    {errors.accountName && (
                        <span className="text-red-500">
                            {errors.accountName.message}
                        </span>
                    )}
                </div>

                {/* Account Number */}
                <div className="flex flex-col gap-1">
                    <label>Account Number</label>
                    <input
                        type="text"
                        placeholder="Enter Account Number"
                        className="w-full border rounded h-[38px] focus:outline-none px-2"
                        {...register('accountNumber', {
                            required: 'Account number is required',
                        })}
                    />
                    {errors.accountNumber && (
                        <span className="text-red-500">
                            {errors.accountNumber.message}
                        </span>
                    )}
                </div>

                {/* Bank Name */}
                <div className="flex flex-col gap-1">
                    <label>Bank Name</label>
                    <input
                        type="text"
                        placeholder="Enter Bank Name"
                        className="w-full border rounded h-[38px] focus:outline-none px-2"
                        {...register('bank', {
                            required: 'Bank name is required',
                        })}
                    />
                    {errors.bank && (
                        <span className="text-red-500">
                            {errors.bank.message}
                        </span>
                    )}
                </div>

                {/* IFSC Code */}
                <div className="flex flex-col gap-1">
                    <label>IFSC Code</label>
                    <input
                        type="text"
                        placeholder="Enter IFSC Code"
                        className="w-full border rounded h-[38px] focus:outline-none px-2"
                        {...register('IFSCCode')}
                    />
                </div>

                {/* Amount Input */}
                <div className="flex flex-col gap-1">
                    <label>Withdraw Amount</label>
                    <div className="flex border rounded">
                        <span className="bg-[#F8F9FA] w-[42px] border-r text-center py-2">
                            ₹
                        </span>
                        <input
                            type="number"
                            placeholder="Amount"
                            className="w-full h-[38px] focus:outline-none ml-2"
                            {...register('amount', {
                                required: 'Amount is required',
                            })}
                        />
                    </div>
                    {errors.amount && (
                        <span className="text-red-500">
                            {errors.amount.message}
                        </span>
                    )}
                </div>

                {/* Submit Button */}
                <button
                    type="submit"
                    className={`p-2 flex justify-center items-center border gap-2 border-[#757575] mx-auto rounded mb-4 w-full text-white ${
                        isLoading ? 'bg-slate-400' : 'bg-green-700'
                    }`}
                    disabled={isLoading || isSubmitting}
                >
                    {isLoading || isSubmitting ? 'Submitting...' : 'Submit'}
                    {(isLoading || isSubmitting) && (
                        <Loader scale={2} color="white" />
                    )}
                </button>
            </form>
        </>
    );
};

export default WithdrawalBank;
