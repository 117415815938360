import { startTransition, useEffect, useState } from 'react';
import Layout from '../../components/Common/Layout/Layout';
import UploadImgDialog from '../../components/UploadImgDialog/UploadImgDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { MdContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useGetBattle, useShareRoomCode } from '../../apis/hooks/useBattles';
import { Tooltip } from 'react-tooltip';
import User from '../../assets/UserProfile';

import vsImg from '../../assets/VS.webp';
import { io, Socket } from 'socket.io-client';
import { useUserData } from '../../Context/UserContext';
import Loader from '../../components/Common/Loader/Loader';
import { IoArrowBack } from 'react-icons/io5';
import GameRules from '../../components/GameRules/GameRules';
// import { useSocket } from '../../Context/SocketContext';
import { BATTLE_STATUS, RESULT_STATUS } from '../../Common/constants';
import { useSound } from '../../apis/hooks/useSound';
import { useSocket } from '../../Context/SocketContext';

const Room = () => {
    const navigate = useNavigate();
    const { message } = useSocket();
    // const [socket, setSocket] = useState<any>(null);
    // const [message, setMessage] = useState<any>();
    const { playSound } = useSound();

    const { id: battle_id } = useParams<{ id: string }>();
    const [roomCode, setRoomCode] = useState('');
    const [open, setIsOpen] = useState(false);
    const [status, setStatus] = useState('');
    const [challengerResult, setChallengerResult] = useState<any>();
    const [acceptorResult, setAcceptorResult] = useState<any>();
    const { user } = useUserData();
    const {
        isLoading,
        data: battleDetails,
        refetch,
    } = useGetBattle({
        id: battle_id ?? '',
    });
    const { mutate: sendRoomCode } = useShareRoomCode({
        id: battle_id ?? '',
    });

    const [showGameRules, setShowGameRules] = useState<boolean>(false);

    // useEffect(() => {
    //     const url: string =
    //         process.env.REACT_APP_API_SOCKET_CONNECTION ||
    //         // 'https://starfish-app-cnmgd.ondigitalocean.app';
    //         'https://oyster-app-psc6w.ondigitalocean.app';

    //     console.log(`Socket URL: ${url}`);
    //     const socketInstance = io(url, {
    //         transports: ['websocket', 'polling'],
    //         reconnection: true,
    //         reconnectionAttempts: 5,
    //         reconnectionDelay: 1000,
    //     });

    //     const handleMessage = (newMessage: any) => {
    //         console.log('New message received:', newMessage);
    //         setMessage(newMessage);
    //     };

    //     socketInstance.on('connect', () => {
    //         console.log('Socket connected:', socketInstance.id);
    //     });

    //     socketInstance.on('onMessage', handleMessage);
    //     setSocket(socketInstance);

    //     return () => {
    //         socketInstance.off('onMessage', handleMessage);
    //         socketInstance.disconnect();
    //     };
    // }, []);

    useEffect(() => {
        console.log('Room Code Area Message: ', message);
        const action = message?.data?.action;
        switch (action) {
            case 'roomCodeSend':
            case 'cancelBattle':
                // if (
                //     message?.data?.data?.acceptor?.id === user?.userdetails?.id
                // ) {
                // playSound();
                refetch();
                // }
                break;
            // case 'cancelBattle':
            //     if (
            //         message?.data?.data?.acceptor?.id === user?.userdetails?.id
            //     ) {
            //         refetch();
            //     }
            //     break;

            default:
                break;
        }
    }, [message]);

    useEffect(() => {
        if (battleDetails) {
            const challengerResultHistory = battleDetails?.battleHistory?.find(
                (d: any) => d.challengerResult
            );
            const acceptorResultHistory = battleDetails?.battleHistory?.find(
                (d: any) => d.acceptorResult
            );

            setChallengerResult(challengerResultHistory ?? null);
            setAcceptorResult(acceptorResultHistory ?? null);
        }
    }, [battleDetails]);

    const onSubmitCode = async () => {
        if (!roomCode) {
            toast('Please enter room code', { type: 'error' });
            return;
        }

        if (roomCode?.trim()?.length !== 8) {
            toast('RoomCode must contain 8 digits', { type: 'error' });
            return;
        }

        const payload = {
            battleId: battle_id,
            roomCode: roomCode,
        };

        sendRoomCode(payload, {
            onSuccess: (res: any) => {
                if (res?.data?.data?.roomCode) {
                    battleDetails.roomCode = res?.data?.data?.roomCode;
                    toast(res?.data?.message ?? 'Room code sent successfully', {
                        type: 'success',
                    });
                } else {
                    toast(res?.data?.message ?? 'Failed to send room code', {
                        type: 'error',
                    });
                }
            },
            onError: (error: any) => {
                toast(
                    Array.isArray(error?.message)
                        ? error?.message[0]
                        : 'Something went wrong',
                    { type: 'error' }
                );
            },
        });
    };

    const handleResponse = (status: string) => {
        switch (status) {
            case RESULT_STATUS.WON:
                setStatus(RESULT_STATUS.WON);
                setIsOpen(true);
                break;
            case RESULT_STATUS.LOST:
                setStatus(RESULT_STATUS.LOST);
                setIsOpen(true);

                break;
            case RESULT_STATUS.CANCELLED:
                setStatus(RESULT_STATUS.CANCELLED);
                setIsOpen(true);

                break;
            default:
                return null;
        }
    };

    const openLudoGame = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android/.test(userAgent);

        if (isMobile) {
            // Mobile device
            const isIOS = /iphone|ipad|ipod/.test(userAgent);
            const isAndroid = /android/.test(userAgent);

            if (isIOS) {
                window.open(
                    'https://apps.apple.com/app/ludo-king/id1631780337',
                    '_blank'
                );
                // window.location.href =
                //     'https://play.google.com/store/apps/details?id=com.ludo.king'; // Replace with your iOS app deep link
            } else if (isAndroid) {
                window.open(
                    'https://apps.apple.com/app/ludo-king/id1631780337',
                    '_blank'
                );

                // window.location.href =
                //     'https://play.google.com/store/apps/details?id=com.ludo.king'; // Replace with your Android app deep link
            } else {
                window.open(
                    'https://apps.apple.com/app/ludo-king/id1631780337',
                    '_blank'
                );

                // Default behavior if not specifically iOS or Android (handle edge cases)
                // window.location.href =
                //     'https://play.google.com/store/apps/details?id=com.ludo.king';
            }
        } else {
            window.open(
                'https://apps.apple.com/app/ludo-king/id1631780337',
                '_blank'
            );

            // Desktop or non-standard mobile browser behavior
            // window.location.href =
            //     'https://play.google.com/store/apps/details?id=com.ludo.king';
        }
    };

    const renderGameResults = () => (
        <div className="flex flex-col justify-center items-center gap-2 border rounded mb-2 w-[90%] mx-auto pb-4 shadow-xl border-[#0000002d]">
            <span className="w-full bg-[#F8F9FA] py-2 text-center border-b ">
                Game Results
            </span>

            {battleDetails?.status === BATTLE_STATUS.CANCELLED ? (
                <div className="text-center gap-2 mt-4 w-[90%] mx-4 text-[red]">
                    This battle is Cancelled
                </div>
            ) : (challengerResult &&
                  battleDetails?.challenger?.id === user?.userdetails?.id) ||
              (acceptorResult &&
                  battleDetails?.acceptor?.id === user?.userdetails?.id) ? (
                renderUserResponse()
            ) : (
                renderPostGameActions()
            )}
        </div>
    );

    const renderWonResponse = () => {
        return (
            <>
                <p>
                    Status :{' '}
                    <span className="text-green-600">{RESULT_STATUS.WON}</span>
                </p>
                <p>Please Wait for final Results</p>
                <img
                    className="p-2"
                    src={challengerResult?.file?.fullUrl ?? ''}
                    alt="status"
                />
            </>
        );
    };

    const renderLostResponse = () => {
        return (
            <>
                <p>
                    Status :{' '}
                    <span className="text-red-600">{RESULT_STATUS.LOST}</span>
                </p>
                <p>Please Wait for final Results</p>
            </>
        );
    };

    const renderCancelResponse = (result: any) => {
        return (
            <>
                <p>
                    Status :{' '}
                    <span className="text-red-600">
                        {RESULT_STATUS.CANCELLED}
                    </span>
                </p>
                <div>
                    <h1>Reason: {result?.cancelReason ?? ''}</h1>
                    <p>Description: {result?.cancelDescription ?? ''}</p>
                </div>
                <p>Please Wait for final Results</p>
            </>
        );
    };

    const renderUserResponse = () => (
        <>
            <div className="flex flex-col items-center justify-between gap-2">
                Your Response
                {challengerResult?.challengerResult === RESULT_STATUS.WON &&
                    battleDetails?.challenger?.id === user?.userdetails?.id && (
                        <>{renderWonResponse()}</>
                    )}
                {acceptorResult?.acceptorResult === RESULT_STATUS.WON &&
                    battleDetails?.acceptor?.id === user?.userdetails?.id && (
                        <>{renderWonResponse()}</>
                    )}
                {challengerResult?.challengerResult === RESULT_STATUS.LOST &&
                    battleDetails?.challenger?.id === user?.userdetails?.id && (
                        <>{renderLostResponse()}</>
                    )}
                {acceptorResult?.acceptorResult === RESULT_STATUS.LOST &&
                    battleDetails?.acceptor?.id === user?.userdetails?.id && (
                        <>{renderLostResponse()}</>
                    )}
                {challengerResult?.challengerResult ===
                    RESULT_STATUS.CANCELLED &&
                    battleDetails?.challenger?.id === user?.userdetails?.id && (
                        <>{renderCancelResponse(challengerResult)}</>
                    )}
                {acceptorResult?.acceptorResult === RESULT_STATUS.CANCELLED &&
                    battleDetails?.acceptor?.id === user?.userdetails?.id && (
                        <>{renderCancelResponse(acceptorResult)}</>
                    )}
            </div>
        </>
    );

    const renderPostGameActions = () => (
        <>
            <div className="flex justify-between gap-2 mt-4 w-[90%] mx-4 text-center">
                After completion of your game, select the status of the game and
                post your screenshot below
            </div>
            <div className="flex flex-col items-center w-full gap-4 text-xl">
                <button
                    className={`w-[90%] rounded-lg p-2 ${
                        battleDetails?.roomCode
                            ? 'bg-[#198754] text-[#fff] hover:bg-[#157347] hover:border-[#146c43]'
                            : 'bg-gray-400 cursor-not-allowed opacity-60'
                    }`}
                    onClick={() => handleResponse(RESULT_STATUS.WON)}
                    disabled={!battleDetails?.roomCode}
                >
                    I WON
                </button>
                <button
                    className={`w-[90%] rounded-lg p-2 ${
                        battleDetails?.roomCode
                            ? 'bg-[#dc3545] text-[#fff] hover:bg-[#bb2d3b] hover:border-[#b02a37]'
                            : 'bg-gray-400 cursor-not-allowed opacity-60'
                    }`}
                    onClick={() => handleResponse(RESULT_STATUS.LOST)}
                    disabled={!battleDetails?.roomCode}
                >
                    I LOST
                </button>
                <button
                    className="border border-[#212529] hover:bg-[#212529] hover:text-white w-[90%] rounded-lg p-2"
                    onClick={() => handleResponse(RESULT_STATUS.CANCELLED)}
                >
                    CANCEL
                </button>
            </div>
        </>
    );

    const renderFinalResults = () => (
        <div className="flex flex-col justify-center items-center gap-2 border rounded mb-2 w-[90%] mx-auto pb-4 shadow-xl border-[#0000002d] font-semibold">
            <span className="w-full bg-[#F8F9FA] py-2 px-4 text-center border-b ">
                Final Results
            </span>
            <div
                className={`flex justify-center gap-2 text-2xl mt-4 w-[90%] mx-4 ${
                    battleDetails?.winner?.id === user?.userdetails?.id
                        ? 'bg-gradient-to-r from-pink-600 via-orange-500 to-red-500 inline-block text-transparent bg-clip-text'
                        : 'bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text'
                }`}
            >
                {battleDetails?.winner?.id === user?.userdetails?.id
                    ? 'Congratulations! You Won! the battle'
                    : 'Well Played!'}
            </div>
        </div>
    );

    return (
        <Layout>
            {isLoading ? (
                <div className="flex flex-col gap-8 items-center justify-center h-[300px]">
                    <Loader scale={4} />
                    <p>Please Wait. Loading...</p>
                </div>
            ) : !battleDetails?.id ? (
                <div className="mx-auto font-bold text-center text-red-500">
                    Unable to find room. Please try again.
                </div>
            ) : (
                <>
                    <div className="w-[90%] mx-auto flex justify-between pt-2 pb-4">
                        <button
                            className=" cursor-pointer flex gap-1 text-[#0d6efd] hover:text-white hover:bg-[#0d6efd]  border border-[#0d6efd] items-center rounded-md leading-loose px-2"
                            onClick={() => navigate(-1)}
                        >
                            <IoArrowBack />
                            Back
                        </button>
                        <button
                            className=" cursor-pointer flex gap-1 text-[#c73232] hover:text-white hover:bg-[#c73232]   border border-[#c73232] items-center px-2 rounded-md leading-loose"
                            onClick={() => setShowGameRules(true)}
                        >
                            Game Rules
                        </button>
                    </div>

                    <div className="p-2 text-sm mb-2 font-semibold border border-[#0000002d]  rounded-md w-[90%] mx-auto shadow-xl">
                        <div className="flex items-center justify-between">
                            {/* Challenger section */}
                            <div
                                className="flex items-center gap-2 cursor-pointer"
                                data-tooltip-id="challengerUsername"
                                data-tooltip-content={
                                    battleDetails?.challenger?.username
                                }
                            >
                                <User className="w-[28px] h-[28px] rounded-xl border" />
                                <p className="w-[60px]">
                                    {battleDetails?.challenger?.username?.slice(
                                        0,
                                        5
                                    ) + '...'}
                                </p>
                            </div>

                            {/* VS image and amount section */}
                            <div className="flex items-center">
                                <img src={vsImg} className="w-[30px] h-10" />
                            </div>

                            {/* Acceptor section */}
                            <div
                                className="flex items-center gap-2 cursor-pointer"
                                data-tooltip-id="acceptorUsername"
                                data-tooltip-content={
                                    battleDetails?.acceptor?.username
                                }
                            >
                                <p className="w-[60px]">
                                    {battleDetails?.acceptor?.username?.slice(
                                        0,
                                        5
                                    ) + '...'}
                                </p>
                                <User className="w-[28px] h-[28px] rounded-xl border" />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <span className="font-bold text-[#198754] pt-2">
                                Rs {battleDetails?.amount}
                            </span>
                        </div>

                        {/* Tooltip for challenger and acceptor usernames */}
                        <Tooltip id="challengerUsername" />
                        <Tooltip id="acceptorUsername" />
                    </div>

                    <div className="flex flex-col justify-center items-center border border-[#0000002d] rounded mb-2 w-[90%] mx-auto shadow-xl">
                        <p className="w-full bg-[#F8F9FA] p-4 text-center border-b text-sm">
                            𝖮𝗉𝗉𝗈𝗇𝖾𝗇𝗍 का एक टोकन खुलने के बाद यदि आप 𝖦𝖺𝗆𝖾 𝖫𝖾𝖿𝗍
                            करते है तो 𝖮𝗉𝗉𝗈𝗇𝖾𝗇𝗍 को 25% 𝖶𝗂𝗇 कर दिया जायेगा ! 𝖠𝗎𝗍𝗈
                            𝖤𝗑𝗂𝗍 के कैश में 𝖠𝖽𝗆𝗂𝗇𝗌 का निर्णय ही अंतिम होगा जिसे
                            आपको मान ना होगा ! लेकिन यदि आप गेम को जान भूजकर
                            𝖠𝗎𝗍𝗈 𝖤𝗑𝗂𝗍 में छोड़ देते है तो आपको 𝖫𝗈𝗌𝗌 कर दिया
                            जायेगा
                        </p>
                    </div>

                    <div className="flex flex-col justify-center gap-4 items-center border rounded m-2 w-[90%] mx-auto shadow-xl border-[#0000002d]">
                        <p className="w-full bg-[#F8F9FA] px-4 py-2 text-center border-b">
                            Room Code
                        </p>
                        <div className="flex items-center justify-between w-[90%] mx-auto gap-1">
                            {!battleDetails?.roomCode ? (
                                battleDetails?.challenger?.id ===
                                    user?.userdetails?.id &&
                                battleDetails?.status !==
                                    BATTLE_STATUS.CANCELLED ? (
                                    <>
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            maxLength={8}
                                            minLength={8}
                                            placeholder="Paste Room Code"
                                            className="p-2 border rounded w-[150px] sm:w-[200px] flex-1"
                                            onChange={(e: any) =>
                                                setRoomCode(
                                                    e.target.value?.trim()
                                                )
                                            }
                                        />
                                        <button
                                            className="bg-[#198754] text-[#fff] rounded p-2"
                                            onClick={onSubmitCode}
                                        >
                                            Send
                                        </button>
                                    </>
                                ) : battleDetails?.status ===
                                  BATTLE_STATUS.CANCELLED ? (
                                    <div className="mx-auto font-bold text-red-500">
                                        Cancelled
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center w-full gap-4">
                                        <Loader scale={2} />
                                        <p className="mx-auto font-bold">
                                            Please wait for room code...
                                        </p>
                                    </div>
                                )
                            ) : (
                                <span className="p-2 mx-auto text-3xl font-bold">
                                    {battleDetails?.roomCode || roomCode}
                                </span>
                            )}
                        </div>

                        <div className="flex flex-col items-center justify-center w-[90%] mx-auto gap-4 mb-4">
                            <button
                                className="bg-[#0d6efd] text-[#fff] rounded p-2 w-full"
                                onClick={() => {
                                    if (battleDetails?.roomCode || roomCode) {
                                        navigator.clipboard.writeText(
                                            battleDetails?.roomCode || roomCode
                                        );
                                        toast('Code Copied', {
                                            type: 'success',
                                        });
                                    } else {
                                        toast(
                                            battleDetails?.challenger?.id ===
                                                user?.userdetails?.id
                                                ? 'Please Enter Room Code'
                                                : 'Please wait for room code',
                                            {
                                                type: 'error',
                                            }
                                        );
                                    }
                                }}
                            >
                                <p className="flex items-center justify-center gap-2">
                                    Copy Code
                                    <MdContentCopy />
                                </p>
                            </button>
                            {/* <button
                                className="bg-green-700 text-[#fff] w-[90%] rounded h-[42px]"
                                onClick={() => openLudoGame()}
                            >
                                Open Ludo
                            </button> */}
                        </div>
                    </div>
                    {battleDetails?.status !== BATTLE_STATUS.FINISHED
                        ? renderGameResults()
                        : renderFinalResults()}

                    <div className="flex flex-col justify-center items-center border rounded mb-2 w-[90%] mx-auto shadow-xl border-[#0000002d]">
                        <p className="bg-[#F8F9FA] px-4 py-2 text-center border-b w-full">
                            Penalty
                        </p>
                        <table className="p-2 text-center border w-[90%] mx-auto m-4">
                            <thead className="text-center border">
                                <tr>
                                    <th className="p-2 border w-[30%]">
                                        Amount
                                    </th>
                                    <th className="p-2 border">Reason</th>
                                </tr>
                            </thead>
                            <tbody className="border">
                                <tr className="bg-[#dee2e6]">
                                    <td className="p-2 border">₹100</td>
                                    <td className="p-2 border">
                                        Fraud/Fake Screenshot
                                    </td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">₹50</td>
                                    <td className="p-2 border">Wrong Update</td>
                                </tr>
                                <tr className="bg-[#dee2e6]">
                                    <td className="p-2 border">₹50</td>
                                    <td className="p-2 border">No Update</td>
                                </tr>
                                <tr>
                                    <td className="p-2 border">₹25</td>
                                    <td className="p-2 border">Abusing</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {open && (
                        <UploadImgDialog
                            isOpen={open}
                            onClose={() => {
                                setIsOpen(false);
                                // refetch();
                            }}
                            status={status}
                            battle_id={battle_id ? battle_id : ''}
                            battleDetails={battleDetails}
                        />
                    )}
                </>
            )}
            {showGameRules && (
                <GameRules
                    isOpen={showGameRules}
                    onClose={() => setShowGameRules(false)}
                />
            )}
        </Layout>
    );
};

export default Room;
